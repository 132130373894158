<template>
  <Modalwindow v-show="showModal" @close="closeModal" class="overflow-auto">
    <div class="modal__content">
      <div class="modal__header">
        <h2 class="text-2xl">Отмена платежа</h2>
        <CloseButton class=" absolute top-5 right-5" @close="closeModal"></CloseButton>
      </div>
      <div class="py-5">
        <div class="flex mb-0.5">
          <p class="select-none w-full my-auto">Номер ТС</p>
          <div class="w-full">
            <input class="w-full" type="text" readonly :value="payment.regnum" placeholder="Номер ТС">
          </div>
        </div>
        <div class="flex mb-0.5">
          <p class="select-none w-full my-auto">ФИО</p>
          <div class="w-full">
            <input disabled class="w-full" type="text" readonly :value="payment.driver" placeholder="ФИО">

          </div>
        </div>
        <div class="flex mb-0.5">
          <p class="select-none w-full my-auto">Сумма</p>
          <div class="w-full">
            <input class="w-full" type="text" readonly :value="payment.sum" placeholder="Сумма">
          </div>
        </div>
        <div class="flex mb-0.5">
          <p class="select-none w-full my-auto">Дата оплаты</p>
          <div class="w-full">
            <input class="w-full" type="text" readonly :value="payment.paymentDate" placeholder="Дата оплаты">

          </div>
        </div>
        <div class="flex mb-0.5">
          <p class="select-none w-full my-auto">Номер документа</p>
          <div class="w-full">
            <input class="w-full" type="text" readonly :value="payment.paymentDoc">
          </div>
        </div>
        <div class="flex my-10 confirm">
          <p class="select-none w-1/2 my-auto">Для подтверждения операции введите указанный код: <span
            class="text-blue-500">{{ code_text }}</span></p>
          <input type="text" v-model.trim="code" placeholder="Код подтверждения">

        </div>
        <div class="flex justify-between">
          <button class="px-5 py-2.5 rounded text-white" :disabled="!confirmed"
                  @click="submit"
                  :class="{'bg-green-400 cursor-pointer':confirmed,'bg-gray-500 cursor-default':!confirmed}">
            Подтвердить
          </button>
          <button class="px-5 py-2.5 rounded text-white bg-red-600" @click="closeModal">Отменить</button>
        </div>
      </div>
    </div>

  </Modalwindow>
</template>

<script>
import Modalwindow from '@/components/common/ModalWindow'
import CloseButton from '@/components/common/CloseButton'
import {getRandomInt} from "@/helpers/functions";

export default {
  name: "CancelPaymentModal",
  components: {
    Modalwindow, CloseButton
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    payment: {
      required: true,
      type: Object,
    }
  },
  data: () => ({
    code: null,
    code_text: null,
  }),
  computed: {
    confirmed() {
      return Number.parseInt(this.code) === Number.parseInt(this.code_text);
    },
  },
  watch: {
    showModal(nv) {
      if (nv === true) {
        this.code_text = getRandomInt(1000, 9999)
      }

    }
  },
  methods: {
    closeModal() {
      this.code = ""
      this.code_text = ""
      this.$emit('update:payment', {})
      this.$emit('update:showModal', false)
    },

    submit() {
      if (!this.payment.paymentDoc || this.payment.paymentDoc === '') {
        return  this.$notify({
          title: 'Ошибка',
          text: 'Отсутствует номер документа',
          type: 'error'
        })
      }
      let payload = {
        paymentDoc: this.payment.paymentDoc,
      }

      this.$store.dispatch('clientPayments/cancelUnusedPayment', payload).then(res => {
        this.$notify({
          title: 'Успешно',
          text: res.data.data,
          type: 'success'
        })
        this.$emit('success',true)
        this.closeModal()
      }).catch(err => {
        this.$notify({
          title: 'Ошибка',
          text: err.response.data.message,
          type: 'error'
        })
      })
    }
  },

}
</script>

<style scoped>
.modal__content {
  @apply lg:w-1/2 sm:w-full  dark:bg-black bg-white dark:text-white text-black  p-10 rounded relative lg:border-l-2
}

.modal__header {
  @apply flex justify-between  w-full
}

table {
  @apply w-full text-left table dark:text-gray-400  text-gray-700 border-separate space-y-6 text-sm;
}

thead {
  @apply dark:bg-gray-800 bg-white text-gray-500
}

tbody {
  @apply dark:text-gray-400 text-gray-600
}

input {
  @apply text-black placeholder-gray-600 w-full px-4 py-2.5  text-base dark:placeholder-gray-400
  transition duration-500 ease-in-out transform border-transparent
  bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
  dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
  dark:focus:text-white
}

.confirm input {
  @apply w-1/2;
}
</style>