<template>
  <div class="lg:container mx-auto">
    <div class="flex justify-center dark:bg-gray-800   my-10 " :class="{'mx-3 rounded bg-white shadow-md dark:shadow-none':!dashboard, 'bg-white shadow-md':dashboard, 'p-5':hideNext}">

      <select v-model="perPage" @change="setPerPage" class="dark:text-green-300 text-blue-900 dark:bg-gray-800 bg-white  focus:outline-none"
      >
        <option value="15">15</option>
        <option value="30">30</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="9999">Все</option>
      </select>


      <button v-if="page > 1" class="p-5 inline-flex items-center dark:text-green-300  text-blue-900 dark:hover:text-green-300 hover:text-blue-500"
              @click="setPage(prev)">
        <img class="mr-2 transform rotate-180 " src="@/assets/img/icons/arrow.svg" alt="">
        <span class="sm:hidden md:block">Предыдущие</span>
      </button>
      <button v-if="!hideNext"  class="p-5 items-center inline-flex dark:text-green-300  text-blue-900 dark:hover:text-green-300 hover:text-blue-500" @click="setPage(next)">
        <span class="sm:hidden md:block">Следующие</span>
        <img class="ml-2" src="@/assets/img/icons/arrow.svg" alt="Белтаможсервис">
      </button>

    </div>
  </div>
</template>

<script>

export default {
  name: "SimplePagination",
  data: () => ({
    page: 1,
    perPage:15,
  }),
  props: {
    dashboard:{
      type:Boolean
    },
    hideNext:{
      type:Boolean
    },
    pagination:{
      required:true,
      type:Object
    }
  },
  computed: {
    prev() {
      return this.page - 1
    },
    next() {
      return this.page + 1
    }
  },
  watch:{
    pagination:{
      handler(nv){
        const {page, perPage} = nv
        this.page = page
        this.perPage = perPage
      }
    },
    deep:true
  },
  methods: {
    setPage(value) {
      value > 0 ? this.page = value : false
      this.$emit('update:pagination', {page:this.page, perPage:this.perPage})
    },
    setPerPage() {
      this.$emit('update:pagination', {page:this.page, perPage:this.perPage})
    }
  }


}
</script>

<style scoped>


</style>
